import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/footer/footer";
import Nav from "../components/nav/nav";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
      </Helmet>
      <Nav />
      <div style={{ flex: "1" }}>{children}</div>
      <Footer />
    </div>
  );
};

export default TemplateWrapper;
